import { configureStore } from "@reduxjs/toolkit";

import ApplicationReducer from "@/lib/store/slices/application/application.slice";

export const store = configureStore({
    reducer: {
        ApplicationReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
